import { useCallback, useEffect } from 'react';

import useNewLocalStorage from 'src/utils/useNewLocalStorage';
import paths from 'src/constants/paths';
import { useLocation } from '@reach/router';
import { StringParam, useQueryParams } from 'use-query-params';

//aetna_session expires after 1 hour of activity
const SESSION_TIMEOUT = 60 * 60 * 1000; //1 hr in ms
const UPDATE_ACTIVITY_INTERVAL = 60 * 1000; //1 min in ms

//tracks last activity in /aetna flow
export const useAetnaSession = (options?: {
  storeSignupCode?: (code: string) => void;
}): {
  isAetnaPath: boolean;
  isAetnaSessionActive: boolean;
  clearAetnaSession: () => void;
} => {
  const [aetnaSessionLastActivity, setAetnaSessionLastActivity, removeAetnaSessionLastActivity] =
    useNewLocalStorage<number | null>('aetna_session', null);

  const [query] = useQueryParams({ r: StringParam });

  const isAetnaSessionActive = aetnaSessionLastActivity
    ? Date.now() - aetnaSessionLastActivity < SESSION_TIMEOUT
    : false;

  const { pathname } = useLocation();

  // make sure check is case insensitive
  const isAetnaPath = pathname.toLowerCase().includes(paths.AETNA);
  const isAetnaParam = query.r === 'aetna';

  const startSession = () => {
    setAetnaSessionLastActivity(Date.now());
    console.log('Aetna session set');

    if (options?.storeSignupCode) {
      options?.storeSignupCode('aetna');
    }
  };

  const extendSession = useCallback(() => {
    setAetnaSessionLastActivity((lastActivity) => {
      if (!lastActivity) return Date.now();
      const isActive = Date.now() - lastActivity < SESSION_TIMEOUT;
      return isActive ? Date.now() : lastActivity;
    });
  }, [setAetnaSessionLastActivity]);

  useEffect(() => {
    //remove when not in aetna flow
    if (!isAetnaSessionActive || !isAetnaPath) {
      return;
    }

    const addListeners = () => {
      document.addEventListener('mousemove', handleActivity);
      document.addEventListener('keydown', handleActivity);
    };

    const handleActivity = () => {
      extendSession();
      cleanup();
      setTimeout(addListeners, UPDATE_ACTIVITY_INTERVAL);
    };

    const cleanup = () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };

    addListeners();

    return cleanup;
  }, []);

  useEffect(() => {
    //start session when we land on an Aetna page:
    if (isAetnaPath || isAetnaParam) {
      startSession();
    }
  }, [pathname, query]);

  return {
    isAetnaPath,
    isAetnaSessionActive,
    clearAetnaSession: () => {
      console.log('Aetna session unset');
      removeAetnaSessionLastActivity();
    },
  };
};
