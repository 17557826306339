import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import paths from 'src/constants/paths';

const SLogo = styled.div`
  height: calc(var(--s-nav-height));
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: auto;
    max-width: 120px;
    max-height: calc(var(--s-nav-height) - 24px);
    filter: var(--filter);
  }
`;

const Logo = ({ theme, logo }) => {
  return (
    <Link to={paths.HOME} className={`logo ${theme === 'dark' && 'dark'}`}>
      <SLogo>
        <img src={logo} alt="Catch Homepage" />
      </SLogo>
    </Link>
  );
};

export default Logo;
