import client from 'src/gatsby-plugin-apollo/client';
import { usePublicSession } from './usePublicSession';
import { useQuery, GET_SESSION } from 'src/data';
import { County, getCounty } from './useZipcode';
import { ffmStates } from 'src/utils';
import { useEffect, useMemo } from 'react';
import { useParams } from './useParams';

export interface ExplorerValues {
  year?: number;
  income?: number;
  zip?: string;
  place?: County;
}

// @OE25 update
const CURRENT_YEAR = 2025;
const POSSIBLE_YEARS = [2025];

export const useExplorer = () => {
  const { loading: checkingSession, sessionID, upsertSession, upserting } = usePublicSession();
  const params = useParams();

  const { loading, data } = useQuery(GET_SESSION, {
    client,
    variables: { id: sessionID },
    skip: !sessionID,
    fetchPolicy: 'cache-first',
  });

  const updateSessionWithParams = async () => {
    // only make updates when zip is specified
    if (!params.zip) return;

    const vals = {
      zip: params.zip,
      place: await getCounty(params.zip),
    };

    if (params.income) {
      vals['income'] = params.income;
    }

    if (params.applicants && params.applicants.length > 0) {
      vals['applicants'] = params.applicants;
    }

    if (params.year) {
      vals['year'] = params.year;
    }

    upsertExplorer(vals);
  };

  useEffect(() => {
    if (params.zip && sessionID) {
      updateSessionWithParams();
    }
  }, [sessionID]);

  const current = useMemo(() => {
    return {
      ...(data?.getPublicHealthExplorerData || {}),
      coverageYear: data?.getPublicHealthExplorerData?.coverageYear || CURRENT_YEAR,
    };
  }, [data]);

  const upsertExplorer = async (values, reload = false) => {
    const existingDependents = (current.dependents || [])?.map(({ __typename, ...m }) => m);
    const existingProviders = (current.providersWanted || [])?.map(({ __typename, ...p }) => p);
    const existingDrugs = (current.drugsWanted || [])?.map(({ __typename, ...d }) => d);
    const state = values.place?.state || current.state;

    const input = {
      id: sessionID,
      zipcode: values.zip || current.zipcode,
      state,
      pathwayType: ffmStates.includes(state) ? 'EDE' : 'STATE_EXCHANGE',
      countyfips: values.place?.fips || current.countyfips,
      coverageYear: values.year || current.coverageYear,
      income: 'income' in values ? values.income : current.income,
      dependents: values.applicants
        ? values.applicants.map((a) => ({
            age: a.age,
            relation: a.relation,
            sex: a.sex,
            isApplying: true,
            aptcEligible: true,
            hasMec: false,
            isParent: false,
            isSmoker: false,
          }))
        : existingDependents,
      plannedUsage: values.plannedUsage,
      providersWanted: values.providersWanted || existingProviders,
      drugsWanted: values.drugsWanted || existingDrugs,
      providerPlanID: values.providerPlanID || current.providerPlanID,
    };

    if (sessionID || (values.zip && values.place)) {
      return upsertSession({
        refetchQueries: reload ? ['PublicHealthPlans', 'PublicHealthSession'] : undefined,
        variables: { input },
      });
    } else {
      console.log('Unable to update or create new session', input);
    }
  };

  return {
    loading: loading || checkingSession,
    data: current,
    upsertExplorer,
    upserting,

    // other
    possibleYears: POSSIBLE_YEARS.sort((a, b) => (a < b ? 1 : -1)),
  };
};
